import React from "react";
import { useRouter } from "next/router";
import { useViewerQuery } from "generated/graphql";

export function useUser({ redirectTo = "", redirectIfFound = false }) {
  const { data, loading, refetch } = useViewerQuery();
  const router = useRouter();

  function refresh() {
    return refetch();
  }

  React.useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo || loading) return;
    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !data?.viewer) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && data?.viewer)
    ) {
      router.push(redirectTo);
    }
  }, [data, redirectIfFound, redirectTo, loading]);

  return { user: data?.viewer, refresh };
}
